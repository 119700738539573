var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.funds,"fields":_vm.fields,"store":"accounting.funds","resource":"/accounting/account/funds","enterable":"","creatable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"account_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageAccount',{attrs:{"value":item.account_id}})],1)]}},{key:"organization_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrganization',{attrs:{"id":item.organization_id}})],1)]}},{key:"currency",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageCurrency',{attrs:{"id":item.currency_id}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"currency-filter",fn:function(){return [_c('SSelectCurrency',{attrs:{"value":_vm.filter.currency_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "currency_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "organization_id", $event)},"change":_vm.filterChange}})]},proxy:true}])}),_c('FundModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }